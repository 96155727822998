.value-box {
  letter-spacing: 1px;
  width: 120px;
  display: inline-block;
  border: 2px solid #404040;
  text-align: center;
  vertical-align: middle;
  height: 100px;
  box-shadow: 0 0 40px 0px gray;
  border-radius: 5px;
}

.value-box-name {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  height: 50px;
  vertical-align: middle;
}

.value-box-value {
  font-weight: bold;
  vertical-align: middle;
  font-size: 2em;
}

@keyframes glowing {
  0% { box-shadow: 0 0 -10px black; }
  40% { box-shadow: 0 0 70px 10px black; }
  60% { box-shadow: 0 0 70px 10px black; }
  100% { box-shadow: 0 0 -10px black; }
}

.slow-glow {
  animation: glowing 1000ms;
}

.fast-glow {
  animation: glowing 500ms;
}

.value-box-container {
  display: inline-block;
}

.value-box-name span.glyphicon {
  padding-top: 2px;
  font-size: 2.5em;
}

