.chain-text {
  position: absolute;
  transform: translateX(-50%) translateY(75%) rotate(-90deg);
}

.chain-endpoint {
  letter-spacing: 1px;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  width: 50px;
  height: 100px;
  border-radius: 15px;
  background-color: #939393;
  box-shadow: 0 0 15px 0px gray;
  border: 1px solid gray;
  text-align: center;
}


.chain-fill-height {
  position: relative;
  display: inline-block;
}

.chain-left {
  float: left;
}

.chain-right {
  float: right;
}
