
.order {
  width: 110px;
}

.order button {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3em;
  margin-top: 10px;
  width: 120px;
  border: 1px solid black;
  transition: all 0.2s ease-in-out;
}

.submit-active {
  box-shadow: 0 0 70px 10px black; 
  transition: box-shadow 0.5s;
}

.submite-inactive {
  box-shadow: 0 0 20px 0px gray;
  transition: box-shadow 0.5s;
}

.order button:hover {
  box-shadow: 0px 0px 20px 0px black;
  background-color: inherit;
}

.order input {
  width: 115px;
  font-size: 0.9em;
  color: black;
  border: none !important;
  box-shadow: none !important;
  text-align: center;
}


