.player-card {
  position: relative;
  letter-spacing: 1px;
  width: 200px;
  /*margin: 0px 40px;*/
  height: 100px;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  border-radius: 15px;
}

.ai-player {
  background-color: #939393;
  border: 1px solid gray;
}

.human-player {
  background-color: black;
  border: 1px solid black;
}

.fill-height {
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  width: 200px;
  text-align: center;
}


@keyframes glowing-green-to-red {
  0% { box-shadow: 0 0 40px 4px green; }
  100% { box-shadow: 0 0 40px 4px red; }
}

@keyframes glowing-red-to-green {
  0% { box-shadow: 0 0 40px 4px red; }
  100% { box-shadow: 0 0 40px 4px green; }
}

.glow-red {
  animation: glowing-green-to-red 2000ms forwards;
}

.glow-green {
  animation: glowing-red-to-green 2000ms forwards;
}

.player-card-container {
  display: inline-block;
}

.avatar {
  position: absolute;
  right: -10px;
  top: -30px;
  border-radius: 50% !important;
  width: 60px;
  border: 1px solid block;
  box-shadow: 0 0 15px 1px black;
}
