

.role-image {
  margin-bottom: 20px;
}

.objective-list {
  font-size: 1.2em;
  list-style: none;
}

.kpi-list {
  font-size: 1.2em;
}

.kpi-list li {
  margin-top: 5px;
}

.image-shadow {
  box-shadow: 0px 4px 8px 0px grey;
}

