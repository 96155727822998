
.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered img {
  display: block;
  margin: auto;
  border-radius: 20px;
}

.centered a {
  text-decoration: none;
}

