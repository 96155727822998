.banner {
  position: relative;
  margin-bottom: 10px;
}

.header-logo {
  float: left;
  overflow: hidden;
  margin-top: -2px;
}


.header-logo-banner {
  display: inline;
}

.header-logo-top {
  background-color: #404040;
  height: 84px;
  margin-left: 50px;
  color: white;
  font-size: 4em;
  text-align: center;
}

.header-logo-bottom {
  background-color: #E97221;
  height: 14px;
  margin-top: 2px;
  margin-left: 40px;
}

