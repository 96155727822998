body {
  min-height: 700px;
  min-width: 950px;
  clear: both;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
}

h3 {
  text-decoration: underline;
}

.content {
  margin-left: 15px;
  margin-right: 15px;
}

.btn-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-container a button {
  min-width: 100px;
}

.overlay {
  position: absolute;
  background-color: rgb(91, 93, 96, .9);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}

div.overlay a {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}



