.value-box {
  letter-spacing: 1px;
  width: 120px;
  display: inline-block;
  border: 2px solid #404040;
  text-align: center;
  vertical-align: middle;
  height: 100px;
  box-shadow: 0 0 40px 0px gray;
  border-radius: 5px;
}

.value-box-name {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  height: 50px;
  vertical-align: middle;
}

.value-box-value {
  font-weight: bold;
  vertical-align: middle;
  font-size: 2em;
}

@-webkit-keyframes glowing {
  0% { box-shadow: 0 0 -10px black; }
  40% { box-shadow: 0 0 70px 10px black; }
  60% { box-shadow: 0 0 70px 10px black; }
  100% { box-shadow: 0 0 -10px black; }
}

@keyframes glowing {
  0% { box-shadow: 0 0 -10px black; }
  40% { box-shadow: 0 0 70px 10px black; }
  60% { box-shadow: 0 0 70px 10px black; }
  100% { box-shadow: 0 0 -10px black; }
}

.slow-glow {
  -webkit-animation: glowing 1000ms;
          animation: glowing 1000ms;
}

.fast-glow {
  -webkit-animation: glowing 500ms;
          animation: glowing 500ms;
}

.value-box-container {
  display: inline-block;
}

.value-box-name span.glyphicon {
  padding-top: 2px;
  font-size: 2.5em;
}


.chain-text {
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(75%) rotate(-90deg);
          transform: translateX(-50%) translateY(75%) rotate(-90deg);
}

.chain-endpoint {
  letter-spacing: 1px;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  width: 50px;
  height: 100px;
  border-radius: 15px;
  background-color: #939393;
  box-shadow: 0 0 15px 0px gray;
  border: 1px solid gray;
  text-align: center;
}


.chain-fill-height {
  position: relative;
  display: inline-block;
}

.chain-left {
  float: left;
}

.chain-right {
  float: right;
}

.player-card {
  position: relative;
  letter-spacing: 1px;
  width: 200px;
  /*margin: 0px 40px;*/
  height: 100px;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  border-radius: 15px;
}

.ai-player {
  background-color: #939393;
  border: 1px solid gray;
}

.human-player {
  background-color: black;
  border: 1px solid black;
}

.fill-height {
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  width: 200px;
  text-align: center;
}


@-webkit-keyframes glowing-green-to-red {
  0% { box-shadow: 0 0 40px 4px green; }
  100% { box-shadow: 0 0 40px 4px red; }
}


@keyframes glowing-green-to-red {
  0% { box-shadow: 0 0 40px 4px green; }
  100% { box-shadow: 0 0 40px 4px red; }
}

@-webkit-keyframes glowing-red-to-green {
  0% { box-shadow: 0 0 40px 4px red; }
  100% { box-shadow: 0 0 40px 4px green; }
}

@keyframes glowing-red-to-green {
  0% { box-shadow: 0 0 40px 4px red; }
  100% { box-shadow: 0 0 40px 4px green; }
}

.glow-red {
  -webkit-animation: glowing-green-to-red 2000ms forwards;
          animation: glowing-green-to-red 2000ms forwards;
}

.glow-green {
  -webkit-animation: glowing-red-to-green 2000ms forwards;
          animation: glowing-red-to-green 2000ms forwards;
}

.player-card-container {
  display: inline-block;
}

.avatar {
  position: absolute;
  right: -10px;
  top: -30px;
  border-radius: 50% !important;
  width: 60px;
  border: 1px solid block;
  box-shadow: 0 0 15px 1px black;
}


.order {
  width: 110px;
}

.order button {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3em;
  margin-top: 10px;
  width: 120px;
  border: 1px solid black;
  transition: all 0.2s ease-in-out;
}

.submit-active {
  box-shadow: 0 0 70px 10px black; 
  transition: box-shadow 0.5s;
}

.submite-inactive {
  box-shadow: 0 0 20px 0px gray;
  transition: box-shadow 0.5s;
}

.order button:hover {
  box-shadow: 0px 0px 20px 0px black;
  background-color: inherit;
}

.order input {
  width: 115px;
  font-size: 0.9em;
  color: black;
  border: none !important;
  box-shadow: none !important;
  text-align: center;
}



.game-row {
  padding-top: 10px;
  padding-bottom: 10px;
}


div h3 {
  text-align: center;
}


.steps {
  font-size: 1.2em;
  color: gray;
}

.active-step {
  font-weight: bold;
  transition: font-weight 0.5s;
  color: black;
}

.game-container {
  position: relative;
}

.canvas-container {
  position: absolute;
  top: 150px;
  left: 0;
}

.game-board {
  position: absolute;
  top: 0;
  left: 0;
}

.canvas {
  background: none;
  position: absolute;
  top: 175px;
  left: 0;
  z-index: -1;
}

.left-arrow-end {
	float: left;
	margin-left: 150px;
}

.right-arrow-end {
	float: right;
	margin-right: 150px;
}

.line-label-text {
  font-size: 1.5em;

}

.line-label-text-bottom {
  margin-right: 30px;
  margin-top: 30px;
}

.line-label-text-top {
  margin-left: 30px;
  margin-bottom: 30px;
}

.anchors {
  margin-top: 25px;
}

.game-demo {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

.graph-container {
  margin: 10px auto;
  width: 1000px;
}

.center-audio {
  text-align: center;
  margin: 10px auto;
}

.banner {
  position: relative;
  margin-bottom: 10px;
}

.header-logo {
  float: left;
  overflow: hidden;
  margin-top: -2px;
}


.header-logo-banner {
  display: inline;
}

.header-logo-top {
  background-color: #404040;
  height: 84px;
  margin-left: 50px;
  color: white;
  font-size: 4em;
  text-align: center;
}

.header-logo-bottom {
  background-color: #E97221;
  height: 14px;
  margin-top: 2px;
  margin-left: 40px;
}




.avatar-name {
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}



.role-image {
  margin-bottom: 20px;
}

.objective-list {
  font-size: 1.2em;
  list-style: none;
}

.kpi-list {
  font-size: 1.2em;
}

.kpi-list li {
  margin-top: 5px;
}

.image-shadow {
  box-shadow: 0px 4px 8px 0px grey;
}



.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered img {
  display: block;
  margin: auto;
  border-radius: 20px;
}

.centered a {
  text-decoration: none;
}


body {
  min-height: 700px;
  min-width: 950px;
  clear: both;
}

h2 {
  text-align: center;
  margin-bottom: 30px;
}

h3 {
  text-decoration: underline;
}

.content {
  margin-left: 15px;
  margin-right: 15px;
}

.btn-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-container a button {
  min-width: 100px;
}

.overlay {
  position: absolute;
  background-color: rgb(91, 93, 96, .9);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}

div.overlay a {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}




