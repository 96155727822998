.game-row {
  padding-top: 10px;
  padding-bottom: 10px;
}


div h3 {
  text-align: center;
}


.steps {
  font-size: 1.2em;
  color: gray;
}

.active-step {
  font-weight: bold;
  transition: font-weight 0.5s;
  color: black;
}

.game-container {
  position: relative;
}

.canvas-container {
  position: absolute;
  top: 150px;
  left: 0;
}

.game-board {
  position: absolute;
  top: 0;
  left: 0;
}

.canvas {
  background: none;
  position: absolute;
  top: 175px;
  left: 0;
  z-index: -1;
}

.left-arrow-end {
	float: left;
	margin-left: 150px;
}

.right-arrow-end {
	float: right;
	margin-right: 150px;
}

.line-label-text {
  font-size: 1.5em;

}

.line-label-text-bottom {
  margin-right: 30px;
  margin-top: 30px;
}

.line-label-text-top {
  margin-left: 30px;
  margin-bottom: 30px;
}

.anchors {
  margin-top: 25px;
}

.game-demo {
  transform: scale(0.9);
  transform-origin: 50% 0%;
}
